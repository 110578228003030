import Vue from "vue";
import VueRouter from "vue-router";
import landing from "../views/landing.vue";
import success from "../views/success.vue";
import canceled from "../views/canceled.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: landing,
  },
  {
    path: "/success",
    name: "success",
    component: success,
  },
  {
    path: "/canceled",
    name: "canceled",
    component: canceled,
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/auth/register.vue"),
  // },
  {
    path: "/byinviteonly",
    name: "physical",
    component: () => import("../views/auth/physical.vue"),
  },
  {
    path: "/register",
    name: "virtual",
    component: () => import("../views/auth/virtual.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
