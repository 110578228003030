<template>
  <div class="register pb-5">
    <div class="container-lg">
      <div class="row mb-3">
        <div class="col-sm-12">
          <img
            src="@/assets/img/register/reg-banner.jpg"
            class="img-fluid"
            alt="registration banner"
          />
        </div>
      </div>
      <h1 class="text-center">Payment Unsuccessful</h1>
      <p class="text-center">
        Registration for PDP Week 2024 was unsuccessful. Please submit the
        <a href="/register">registration form</a> again if you would like to
        confirm your participation. For further enquiries, please contact us at
        <a href="mailto:pdp.enquiries@pico.com" target="_blank"
          >pdp.enquiries@pico.com</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "canceled",
};
</script>

<style lang="scss" scoped>
.register {
  & label {
    font-weight: 500;
  }

  & .sessions {
    & .session {
      & .card {
        border-radius: 50px !important;
      }

      label {
        font-weight: 700;
      }
    }
  }
}
</style>
